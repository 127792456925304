import { Divider, IconButton } from "@mui/material";
import { CSSProperties, Fragment, useState } from "react";
import styles from "./ordersList.module.css";
import { Edit } from "@mui/icons-material";

export interface Props {
  data: any[];
  title: string;
  style?: CSSProperties;
  onOptionClick?: (status: any) => void;
  editOrderStatuses?: () => void;
}

export default function LegendComponent(props: Props) {
  const { data, title, style, onOptionClick, editOrderStatuses } = props;
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const handleClick = (status: any) => {
    if (selectedStatus === status.displayName) {
      setSelectedStatus(null); // Set selected item by its unique property
      onOptionClick?.({ _id: "" });
    } else {
      setSelectedStatus(status.displayName); // Set selected item by its unique property
      onOptionClick?.(status); // Call the callback function if provided
    }
  };

  return (
    <div style={style}>
      <fieldset
        className={styles.fieldsetContent}
        style={{
          border: "1px solid #0000001f",
          borderRadius: 10,
          marginTop: 20,
        }}
      >
        <legend className={styles.lengendComponent}>
          {title}{" "}
          {editOrderStatuses && (
            <IconButton
              aria-label="edit"
              onClick={editOrderStatuses}
              edge="end"
            >
              <Edit />
            </IconButton>
          )}
        </legend>
        {data.map((status: any, key: any) => (
          <Fragment key={key}>
            <div
              onClick={() => handleClick(status)}
              style={{
                backgroundColor:
                  selectedStatus === status.displayName
                    ? "#dadbdd"
                    : "transparent",
              }}
              className={styles.legentElement}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: status.color,
                    margin: 10,
                    borderRadius: 10,
                  }}
                ></div>
                <p
                  style={{
                    margin: 13,
                    verticalAlign: "center",
                    color: "#4d5e80",
                  }}
                >
                  {status.displayName}
                </p>
              </div>
            </div>
            {key !== data.length - 1 && <Divider />}
          </Fragment>
        ))}
      </fieldset>
    </div>
  );
}
