import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { OrderStatusModel } from "../../Models/OrderStatusModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { HexColorPicker } from "react-colorful";
import { forwardRef } from "react";

interface AddOrderStatusProps {
  onSubmit: (data: any) => void;
}

const AddOrderStatus = forwardRef<HTMLFormElement, AddOrderStatusProps>(
  ({ onSubmit }, ref) => {
    const { control, handleSubmit } = useForm({
      defaultValues: {
        name: "",
        displayName: "",
        color: "#ffffff",
      },
      resolver: yupResolver(OrderStatusModel),
    });

    return (
      <form
        ref={ref}
        onSubmit={handleSubmit(onSubmit)} // Use handleSubmit here
        id="addNewOrderStatus"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "10px 10px 10px 10px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Controller
              name="name"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  fullWidth
                  label={Vocabulary.name}
                  variant="outlined"
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="displayName"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  style={{ marginRight: "10px" }}
                  fullWidth
                  label={Vocabulary.displayName}
                  variant="outlined"
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
          <Controller
            name="color"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <HexColorPicker
                color={value}
                style={{ height: "100px" }}
                onChange={onChange}
              />
            )}
          />
        </div>
      </form>
    );
  }
);

export default AddOrderStatus;
