import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { useLocation, useNavigate } from "react-router";
import { tableLayoutOnMobile } from "../../Utils/Constants";
import moment from "moment";
import { europeanDate, usDateFormatWithTime } from "../../Utils/formatDate";
import { Button, InputAdornment, TextField } from "@mui/material";
import GenericModal from "../Generic/GenericModal";
import GenericDialog from "../Generic/GenericDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { deleteData } from "../../Services/deleteData";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postData } from "../../Services/postData";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UserForm from "./UserForm";
import { UserModel } from "../../Models/UserModel";

const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openDeleteModal, setOpenDeleteModal]: any = useState(false);
  const [openEditModal, setOpenEditModal]: any = useState(false);
  const { users, roles, nrUsers, getUsers, getRoles, filterUsers } = useGlobalContext();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [searchText, setSearchText] = useState("");
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {},
    resolver: yupResolver(UserModel),
  });

  useEffect(() => {
    getUsers(page + 1, perPage);
    getRoles();
    // eslint-disable-next-line
  }, [currentPath]);

  const handleSearch = () => {
    if (searchText.length >= 3) {
      filterUsers(page + 1, perPage, searchText);
    } else {
      getUsers(page + 1, perPage);
      getRoles();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  /**
   *
   */
  const options = {
    filter: false,
    download: false,
    responsive: tableLayoutOnMobile,
    sort: false,
    textLabels: {
      viewColumns: {
        title: "Afisare coloane",
        titleAria: "Afisare Coloane",
      },
      pagination: Vocabulary.pagination,
    },

    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: page,
    serverSide: true,
    rowsPerPage: perPage,
    search: false,
    count: nrUsers,
    rowHover: true,
    onChangePage: (page: number) => {
      setPage(page);
      setCurrentPath(`${localUrlEnum.users}?page=${page}&perPage=${perPage}`);
      navigate(`${localUrlEnum.users}?page=${page}?perPage=${perPage}`);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setPerPage(numberOfRows);
      setCurrentPath(`${localUrlEnum.users}?page=${0}&perPage=${perPage}`);
      navigate(`${localUrlEnum.users}?page=${0}?perPage=${numberOfRows}`);
      window.scrollTo(0, 0);
    },
  };

  function addOrEditUser() {
    const newUser = getValues();
    postData(urlEnum.register, newUser).then((res) => {
      getUsers(page + 1, perPage);
      setOpenAddUser(false);
      setOpenEditModal(false);
    });
  }

  function deleteUser() {
    const deleteUserUrl = `${urlEnum.users}/${(openDeleteModal as any)._id}`;
    deleteData(deleteUserUrl).then((res) => {
      setOpenDeleteModal(false);
      getUsers(page + 1, perPage);
    });
  }

  const categoriesColumns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "firstName",
      label: Vocabulary.firstName,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastName",
      label: Vocabulary.lastName,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "username",
      label: Vocabulary.username,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: Vocabulary.phoneNumber,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: Vocabulary.email,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "roleId",
      label: Vocabulary.roles,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[6].displayName || "";
        },
      },
    },
    {
      name: "createdAt",
      label: Vocabulary.updatedAt,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: any) => (
          <>
            {date
              ? moment(date, usDateFormatWithTime).format(europeanDate)
              : ""}
          </>
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right" }}>
            <EditIcon
              style={{
                height: 30,
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenEditModal(users[rowIndex]);
                reset(users[rowIndex], { keepDefaultValues: true });
                setValue("_id", (users[rowIndex] as any)._id as string);
                setValue(
                  "roleId",
                  (users[rowIndex] as any).roleId._id as string
                );
              }}
            />
          </div>
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div>
            <DeleteIcon
              style={{
                height: 30,
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenDeleteModal(users[rowIndex]);
              }}
            />
          </div>
        ),
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
        backgroundColor: "#f7f8fa",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <div style={{ marginRight: "20px", width: "100%", maxWidth: "400px" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Căutare..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update search text
            onKeyPress={handleKeyPress} // Trigger search on Enter
          />
        </div>
        <Button
          onClick={() => {
            reset();
            setOpenAddUser(true);
          }}
          startIcon={<AddCircleIcon />}
          variant="contained"
          style={{
            color: "white",
            padding: "10px 15px 10px 15px",
            fontWeight: 600,
            borderRadius: "10px 10px 0px 0px",
            right: 0,
            zIndex: 1000,
          }}
        >
          {Vocabulary.addNewUser}
        </Button>
      </div>
      <MUIDataTable
        title={"Listă utilizatori"}
        data={users}
        columns={categoriesColumns}
        options={options}
      />
      <GenericModal
        open={openAddUser || openEditModal}
        title={Vocabulary.editUser}
        content={
          <UserForm
            control={control}
            roles={roles}
            handleSubmit={handleSubmit}
            handleSubmitData={() => {}}
          />
        }
        onClose={() => {
          setOpenAddUser(false);
          setOpenEditModal(false);
        }}
        onOk={() => addOrEditUser()}
        onCancel={() => {
          setOpenAddUser(false);
          setOpenEditModal(false);
        }}
        handleSubmit={handleSubmit(addOrEditUser)}
      />
      <GenericDialog
        open={openDeleteModal}
        title={Vocabulary.deleteUser}
        message={Vocabulary.deleteUserMessage}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onOk={() => deleteUser()}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      />
    </div>
  );
};

export default UsersList;
