import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { useLocation, useNavigate } from "react-router";
import { tableLayoutOnMobile } from "../../Utils/Constants";
import moment from "moment";
import { europeanDate, usDateFormatWithTime } from "../../Utils/formatDate";
import { Button, TextField } from "@mui/material";
import GenericModal from "../Generic/GenericModal";
import GenericDialog from "../Generic/GenericDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { deleteData } from "../../Services/deleteData";
import { CategoryModel } from "../../Models/CategoryModel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postData } from "../../Services/postData";
import AddCategory from "./AddCategory";
import { Category as CategoryType } from "../../Types/Category";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { createFormData } from "../../Utils/Utils";
import PreviewIcon from "@mui/icons-material/Preview";
import PreviewCard from "./PreviewCard";
import { toast } from "react-toastify";

const notifyError = (message: string) => toast.error(message);

const CategoriesList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openDeleteModal, setOpenDeleteModal]: any = useState(false);
  const [openEditModal, setOpenEditModal]: any = useState(false);
  const [openPreviewModal, setOpenPreviewModal]: any = useState(false);
  const { categories, nrCategorii, getCategories, filterCategories } = useGlobalContext();
  const [formData, setFormData] = useState<Partial<CategoryType>>({});
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [searchText, setSearchText] = useState("");
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      //rolesArray: ["ROLE_USER"],
    },
    resolver: yupResolver(CategoryModel),
  });

  useEffect(() => {
    getCategories(page + 1, perPage);
    // eslint-disable-next-line
  }, [currentPath]);

  const handleSearch = () => {
    if (searchText.length >= 3) {
      filterCategories(page + 1, perPage,searchText);
    } else {
      getCategories(page + 1, perPage);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  /**
   *
   */
  const options = {
    filter: false,
    download: false,
    responsive: tableLayoutOnMobile,
    sort: false,
    textLabels: {
      viewColumns: {
        title: "Afisare coloane",
        titleAria: "Afisare Coloane",
      },
      pagination: Vocabulary.pagination,
    },

    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: page,
    serverSide: true,
    rowsPerPage: perPage,
    search: false,
    count: nrCategorii,
    rowHover: true,
    onChangePage: (page: number) => {
      setPage(page);
      setCurrentPath(`${localUrlEnum.orders}?page=${page}&perPage=${perPage}`);
      navigate(`${localUrlEnum.categories}?page=${page}?perPage=${perPage}`);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setPerPage(numberOfRows);
      setCurrentPath(`${localUrlEnum.categories}?page=${0}&perPage=${perPage}`);
      navigate(`${localUrlEnum.categories}?page=${0}?perPage=${numberOfRows}`);
      window.scrollTo(0, 0);
    },
  };

  const categoriesColumns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "description",
      label: Vocabulary.description,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "category",
      label: Vocabulary.category,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[3]?.name || "";
        },
      },
    },
    {
      name: "createdAt",
      label: Vocabulary.createAt,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: any) => (
          <>
            {date
              ? moment(date, usDateFormatWithTime).format(europeanDate)
              : ""}
          </>
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div>
            <PreviewIcon
              style={{
                height: 30,
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenPreviewModal(categories[rowIndex]);
              }}
            />
          </div>
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right" }}>
            <EditIcon
              style={{
                height: 30,
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenEditModal(categories[rowIndex]);
                setValue("_id", categories[rowIndex]._id as string);
                setValue("name", categories[rowIndex].name as string);
                setValue(
                  "description",
                  categories[rowIndex].description as string
                );
                setValue(
                  "category",
                  (categories[rowIndex].category as any)?._id
                );
              }}
            />
          </div>
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div>
            <DeleteIcon
              style={{
                height: 30,
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenDeleteModal(categories[rowIndex]);
              }}
            />
          </div>
        ),
      },
    },
  ];

  /**
   *
   */
  function deleteCategory() {
    const deleteCategoryUrl = `${urlEnum.category}/${
      (openDeleteModal as any)._id
    }`;
    deleteData(deleteCategoryUrl).then((res) => {
      setOpenDeleteModal(false);
      getCategories(page, perPage);
    });
  }

  const addOrEditCategory = () => {
    formData.images?.forEach((image: any) => {
      const addedImages: any = getValues("images") || [];
      setValue("images", [...addedImages, image?.name]);
    });
    setValue("files", formData.files);
    const newUserData = getValues();
    let categoryFormData = createFormData(newUserData);
    postData(urlEnum.category, categoryFormData).then((res) => {
      setOpenAddCategory(false);
      getCategories(page, perPage);
    });
  };

  const displayDropzoneImages = (images: any) => {
    setFormData((prev) => ({ ...prev, images: images }));
  };

  const handleChangeFiles = (files: any) => {
    setFormData((prev) => ({ ...prev, files: files }));
  };

  const handleDeleteImages = (images: any, files: any) => {
    setFormData((prev) => ({ ...prev, files: files, images: images }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
        backgroundColor: "#f7f8fa",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <div style={{ marginRight: "20px", width: "100%", maxWidth: "400px" }}>
        <TextField
              fullWidth
              variant="outlined"
              placeholder="Căutare..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)} // Update search text
              onKeyPress={handleKeyPress} // Trigger search on Enter
            />
        </div>
        <Button
          onClick={() => setOpenAddCategory(true)}
          startIcon={<AddCircleIcon />}
          variant="contained"
          style={{
            color: "white",
            padding: "10px 15px 10px 15px",
            fontWeight: 600,
            borderRadius: "10px 10px 0px 0px",
            right: 0,
            zIndex: 1000,
          }}
        >
          {Vocabulary.addNewCategory}
        </Button>
      </div>
      <MUIDataTable
        title={"Listă categorii"}
        data={categories}
        columns={categoriesColumns}
        options={options}
      />
      <GenericDialog
        open={openDeleteModal}
        title={Vocabulary.deleteCategory}
        message={Vocabulary.deleteCategoryMessage}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onOk={() => deleteCategory()}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      />
      <GenericModal
        open={openAddCategory || openEditModal}
        title={Vocabulary.addNewCategory}
        content={
          <AddCategory
            control={control}
            handleSubmit={handleSubmit}
            handleSubmitData={addOrEditCategory}
            categories={categories}
            formData={formData}
            displayDropzoneImages={displayDropzoneImages}
            handleChangeFiles={handleChangeFiles}
            handleDeleteImages={handleDeleteImages}
            mode="create"
          />
        }
        onClose={() => {
          setOpenAddCategory(false);
          setOpenEditModal(false);
        }}
        onOk={() => addOrEditCategory()}
        onCancel={() => {
          setOpenAddCategory(false);
          setOpenEditModal(false);
        }}
        handleSubmit={handleSubmit(addOrEditCategory)}
      />
      {openPreviewModal?.images?.length > 0 ? (
        <GenericModal
          open={openPreviewModal}
          title={""}
          content={<PreviewCard imageLinks={openPreviewModal.images} />}
          onClose={() => {
            setOpenPreviewModal(false);
          }}
          onCancel={() => {
            setOpenPreviewModal(false);
          }}
        />
      ) : (
        openPreviewModal && notifyError(Vocabulary.imageDoesNotExist)
      )}
      <GenericDialog
        open={openDeleteModal}
        title={Vocabulary.deleteCategory}
        message={Vocabulary.deleteCategoryMessage}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onOk={() => deleteCategory()}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      />
    </div>
  );
};

export default CategoriesList;
