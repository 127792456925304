import { Button, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { jwtDecode } from "jwt-decode";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteAccountModel } from "../../Models/DeleteAccountModel";
import styles from "./deleteAccountPage.module.css";
import { deleteData } from "../../Services/deleteData";
import { toast } from "react-toastify";

export default function DeleteAccount() {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(DeleteAccountModel),
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("Clearing localStorage...");
    localStorage.clear();
  }, []);

  /**
   *
   */
  async function handleUserDataSubmit(payload: any) {
    // eslint-disable-next-line no-console
    const params = new URLSearchParams(location.search);
    const token: any = params.get("token");
    if (!token) {
      navigate(localUrlEnum.login);
      toast.error(Vocabulary.accountDeletionDenyMessage);
    }
    localStorage.setItem("access_token", token);
    if (token) {
      try {
        // Decode the token
        const decoded: any = jwtDecode(token);
        payload.username = decoded.username;
        const response = await deleteData(
          `${urlEnum.users}/client?id=${decoded.id}`,
          token
        );
        if (response) {
          toast.success(Vocabulary.accountDeletionSuccessMessage);
        }
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }

  const onError = (model: any) => {
    //
  };

  return (
    <div className={styles.registerContainer}>
      <div className={styles.imageContainer}>
        <img src="/casaVatraSiglaAlb.svg" alt="CASAVATRA Img" />
      </div>
      <div className={styles.formContainer}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid
            component="div"
            sx={{
              flex: 2,
              mt: 8,
              mb: 0,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h1>{Vocabulary.accountDeletion}</h1>
          </Grid>
          <Grid component="div" sx={{ flex: 4, mt: 8, mb: 2, width: "100%" }}>
            <form
              onSubmit={handleSubmit(handleUserDataSubmit, onError)}
              method="post"
              id="checkUserData"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Controller
                name="email"
                defaultValue=""
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    fullWidth
                    style={{
                      marginBottom: 15,
                    }}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                    id="email"
                    label={Vocabulary.email}
                    size="small"
                    variant="outlined"
                    type={"text"}
                  />
                )}
              />

              <Button variant="contained" type="submit">
                {Vocabulary.accountDeletion}
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
