import React, { useEffect, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { useLocation, useNavigate } from "react-router";
import styles from "./ordersList.module.css";
import {
  tableLayoutOnMobile,
} from "../../Utils/Constants";
import moment from "moment";
import { europeanDate, usDateFormatWithTime } from "../../Utils/formatDate";
import { Button, TextField } from "@mui/material";
import GenericModal from "../Generic/GenericModal";
import GenericDialog from "../Generic/GenericDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { deleteData } from "../../Services/deleteData";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postData } from "../../Services/postData";
import { Category as CategoryType } from "../../Types/Category";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddOrder from "./AddOrder";
import { OrderModel } from "../../Models/OrderModel";
import LegendComponent from "./LegendComponent";
import OrderLines from "./OrderLines";
import OrderStatuses from "./OrderStatuses";
import AddOrderStatus from "./AddOrderStatus";
import { updateData } from "../../Services/updateData";
import socket from "../../Utils/Socket";

export default function OrdersList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [openAddOrder, setOpenAddOrder] = useState(false);
  const [openDeleteModal, setOpenDeleteModal]: any = useState(false);
  const [openEditModal, setOpenEditModal]: any = useState(false);
  const [openPreviewModal, setOpenPreviewModal]: any = useState(false);
  const [
    openEditOrderStatusesModal,
    setOpenEditOrderStatusesModal,
  ]: any = useState(false);
  const [
    openAddOrderStatusesModal,
    setOpenAddOrderStatusesModal,
  ]: any = useState(false);

  const [currentPath, setCurrentPath] = useState(location.pathname);
  const {
    products,
    orders,
    statuses,
    users,
    nrOrders,
    getOrders,
    getProducts,
    getCategories,
    getStatuses,
    getUsers,
    getUserBySearchCriteria,
    filterOrders,
  } = useGlobalContext();
  const [formData, setFormData] = useState<Partial<CategoryType>>({});
  const formRef = useRef<HTMLFormElement>(null);
  const updateOrderStatusRef = useRef<HTMLFormElement>(null);
  // const { productLines, setProductLines, getProductLines } = useGlobalContext();
  const [searchText, setSearchText] = useState("");
  const [showColorPicker, setShowColorPicker] = useState([] as any);
  const { control, watch, handleSubmit, getValues, setValue, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(OrderModel),
  });
  const { remove } = useFieldArray({
    control,
    name: "productLines",
  });

  useEffect(() => {
    getOrders(page + 1, perPage, location.search.split("=")[3] || "");
    getStatuses();
    getProducts();
    getCategories();
    getUsers();
    // eslint-disable-next-line
  }, [currentPath]);

  useEffect(() => {
    socket.emit("getOrders");
    socket.on("placedOrders", () => {
      getOrders(page + 1, perPage, location.search.split("=")[3] || "");
    });
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    if (searchText.length >= 3) {
      filterOrders(page + 1, perPage, searchText);
    } else {
      getOrders(page + 1, perPage, location.search.split("=")[3] || "");
      getStatuses();
      getProducts();
      getCategories();
      getUsers();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  /**
   *
   */
  const options = {
    filter: false,
    download: true,
    viewColumns: false,
    responsive: tableLayoutOnMobile,
    sort: false,
    textLabels: {
      viewColumns: {
        title: "Afisare coloane",
        titleAria: "Afisare Coloane",
      },
      pagination: Vocabulary.pagination,
    },

    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: page,
    serverSide: true,
    rowsPerPage: perPage,
    search: false,
    count: nrOrders,
    rowHover: true,
    setRowProps: (row: any) => {
      const status = row[7];
      const backgroundColor =
        (statuses.find((orderStatus: any) => {
          return orderStatus.displayName === status ? orderStatus : null;
        }) as any)?.color || "white";

      return {
        style: { backgroundColor },
      };
    },
    onChangePage: (page: number) => {
      setPage(page);
      setCurrentPath(`${localUrlEnum.orders}?page=${page}&perPage=${perPage}`);
      const filter = location.search.split("=")[3];
      if (filter) {
        navigate(
          `${localUrlEnum.orders}?page=${page}&perPage=${perPage}&filter=${filter}`
        );
      } else {
        navigate(`${localUrlEnum.orders}?page=${page}&perPage=${perPage}`);
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setPerPage(numberOfRows);
      setCurrentPath(
        `${localUrlEnum.orders}?page=${0}}&perPage=${numberOfRows}`
      );
      const filter = location.search.split("=")[3];
      if (filter) {
        navigate(
          `${
            localUrlEnum.orders
          }?page=${0}&perPage=${numberOfRows}&filter=${filter}`
        );
      } else {
        navigate(`${localUrlEnum.orders}?page=${0}&perPage=${numberOfRows}`);
      }
      window.scrollTo(0, 0);
    },
    onRowClick: (rowData: any, rowMeta: any) => {
      setOpenPreviewModal(orders[rowMeta.dataIndex]);
    },
  };

  const categoriesColumns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "firstName",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[1] || "";
        },
      },
    },
    {
      name: "lastName",
      label: Vocabulary.lastName,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[2] || "";
        },
      },
    },
    {
      name: "phoneNumber",
      label: Vocabulary.phoneNumber,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[3] || "";
        },
      },
    },
    {
      name: "address",
      label: Vocabulary.address,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[4] || "";
        },
      },
    },
    {
      name: "discountUser",
      label: Vocabulary.discountUser,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[1]?.discount
            ? `${tableMeta.rowData[1]?.discount} %`
            : "0 %";
        },
      },
    },
    {
      name: "courier",
      label: Vocabulary.courier,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[6]?.firstName
            ? `${tableMeta.rowData[6]?.firstName} ${tableMeta.rowData[6]?.lastName}`
            : "";
        },
      },
    },
    {
      name: "status",
      label: Vocabulary.orderStatus,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[7]?.displayName || "";
        },
      },
    },
    {
      name: "discount",
      label: Vocabulary.discount,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return tableMeta.rowData[3]?.name
            ? `${tableMeta.rowData[3]?.name} %`
            : "0 %";
        },
      },
    },
    {
      name: "productLines",
      label: Vocabulary.price,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          let calculatedPrice = 0;
          tableMeta.rowData[9].forEach((product: any) => {
            calculatedPrice += product.totalPrice * (product.quantity || 1);
          });
          return calculatedPrice ? `${calculatedPrice} lei` : " - ";
        },
      },
    },
    {
      name: "updatedAt",
      label: Vocabulary.updatedAt,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: any) => (
          <>
            {date
              ? moment(date, usDateFormatWithTime).format(europeanDate)
              : ""}
          </>
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right" }}>
            <EditIcon
              style={{
                height: 30,
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenEditModal(orders[rowIndex]);
                setValue("_id", (orders[rowIndex] as any)._id as string);
                setValue("phoneNumber", orders[rowIndex].phoneNumber);
                setValue("firstName", orders[rowIndex].firstName);
                setValue("lastName", orders[rowIndex].lastName);
                setValue("address", orders[rowIndex].address);
                setValue("status", (orders[rowIndex].status as any)._id);
                setValue("productLines", orders[rowIndex].productLines);
              }}
            />
          </div>
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div>
            <DeleteIcon
              style={{
                height: 30,
                cursor: "pointer",
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                setOpenDeleteModal(orders[rowIndex]);
              }}
            />
          </div>
        ),
      },
    },
  ];

  /**
   *
   */
  function deleteOrder() {
    const deleteOrderUrl = `${urlEnum.orders}/${(openDeleteModal as any)._id}`;
    deleteData(deleteOrderUrl).then((res) => {
      setOpenDeleteModal(false);
      getOrders(page + 1, perPage, location.search.split("=")[3] || "");
    });
  }

  const addOrEditOrder = () => {
    const newOrder = getValues();
    postData(urlEnum.orders, newOrder).then((res) => {
      getOrders(page + 1, perPage, location.search.split("=")[3] || "");
      setOpenAddOrder(false);
      setOpenEditModal(false);
    });
  };

  const handleDeleteProductFromOrder = (index: any) => {
    remove(index);
  };

  const filterByStatus = (status: any) => {
    setCurrentPath(
      `${localUrlEnum.orders}?page=${page}&perPage=${perPage}&filter=${status._id}`
    );
    navigate(
      `${localUrlEnum.orders}?page=${page}&perPage=${perPage}&filter=${status._id}`
    );
  };

  const getUsedDataFromServer = async (phoneNumber: string) => {
    try {
      const response: any = await getUserBySearchCriteria(0, 10, phoneNumber);
      setValue("firstName", response.result[0].firstName);
      setValue("lastName", response.result[0].lastName);
      if (response.result[0].address)
        setValue("address", response.result[0].address);
      console.log(response);
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  const editOrderStatuses = () => {
    setOpenEditOrderStatusesModal(true);
  };

  /**
   * Submits the new order status form data to the server and updates the UI.
   * @param {object} data - The form data from the new order status form.
   */
  const addNewOrderStatus = (data: any) => {
    const token = localStorage.getItem("access_token") || "";
    // Add the new order status to the DB
    postData(urlEnum.orderStatuses, data, token).then((res) => {
      getStatuses();
      setOpenAddOrderStatusesModal(false);
    });
  };

  const saveNewOrderStatuses = (data: any) => {
    const token = localStorage.getItem("access_token") || "";
    updateData(urlEnum.orderStatuses, data, token).then((res) => {
      getStatuses();
      setOpenEditOrderStatusesModal(false);
    });
  };

  const deleteOrderStatus = (id: any) => {
    const token = localStorage.getItem("access_token") || "";
    deleteData(`${urlEnum.orderStatuses}/${id}`, token).then((res) => {
      getStatuses();
    });
  };

  return (
    <div className={styles.ordersContainer}>
      <div className={styles.legendContainer}>
        <LegendComponent
          title={Vocabulary.orderStatuses}
          data={statuses}
          onOptionClick={(status: any) => filterByStatus(status)}
          editOrderStatuses={editOrderStatuses}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 10,
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        >
          <div
            style={{ marginRight: "20px", width: "100%", maxWidth: "400px" }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Căutare..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)} // Update search text
              onKeyPress={handleKeyPress} // Trigger search on Enter
            />
          </div>
          <Button
            onClick={() => {
              reset();
              setOpenAddOrder(true);
            }}
            startIcon={<AddCircleIcon />}
            variant="contained"
            style={{
              color: "white",
              padding: "10px 15px 10px 15px",
              fontWeight: 600,
              borderRadius: "10px 10px 0px 0px",
              right: 0,
              zIndex: 1000,
            }}
          >
            {Vocabulary.addNewOrder}
          </Button>
        </div>
        <MUIDataTable
          title={"Listă comenzi"}
          data={orders}
          columns={categoriesColumns}
          options={options}
        />
        <GenericDialog
          open={openDeleteModal}
          title={Vocabulary.deleteOrder}
          message={Vocabulary.deleteCategoryMessage}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          onOk={() => deleteOrder()}
          onCancel={() => {
            setOpenDeleteModal(false);
          }}
        />
        <GenericModal
          open={openAddOrder || openEditModal}
          title={
            getValues("_id") ? Vocabulary.editOrder : Vocabulary.addNewOrder
          }
          content={
            <AddOrder
              control={control}
              handleSubmit={handleSubmit}
              handleSubmitData={addOrEditOrder}
              products={products}
              users={users}
              selectedProducts={watch("productLines")}
              formData={formData}
              statuses={statuses}
              handleDeleteProductFromOrder={handleDeleteProductFromOrder}
              mode="create"
              getUsedDataFromServer={getUsedDataFromServer}
            />
          }
          onClose={() => {
            setOpenAddOrder(false);
            setOpenEditModal(false);
          }}
          onCancel={() => {
            setOpenAddOrder(false);
            setOpenEditModal(false);
          }}
        />
        <GenericModal
          open={openPreviewModal}
          title={""}
          content={<OrderLines selectedProducts={openPreviewModal} />}
          onClose={() => {
            setOpenPreviewModal(false);
          }}
          onCancel={() => {
            setOpenPreviewModal(false);
          }}
        />
        <GenericDialog
          open={openDeleteModal}
          title={Vocabulary.deleteOrder}
          message={Vocabulary.deleteOrderMessage}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          onOk={() => deleteOrder()}
          onCancel={() => {
            setOpenDeleteModal(false);
          }}
        />
        <GenericModal
          open={openEditOrderStatusesModal}
          title={""}
          content={
            <OrderStatuses
              ref={updateOrderStatusRef}
              onSubmit={saveNewOrderStatuses}
              statuses={statuses}
              deleteStatus={deleteOrderStatus}
              showColorPicker={showColorPicker}
              setShowColorPicker={(key: any) => {
                if (!showColorPicker.includes(key)) {
                  setShowColorPicker((prev: any) => [...prev, key]);
                } else {
                  setShowColorPicker((prevState: any) =>
                    prevState.filter((currentKey: any) => key !== currentKey)
                  );
                }
              }}
            />
          }
          onClose={() => {
            setOpenEditOrderStatusesModal(false);
          }}
          onCancel={() => {
            setOpenEditOrderStatusesModal(false);
          }}
          okButtonName={Vocabulary.save}
          onOk={() => {
            if (updateOrderStatusRef.current) {
              updateOrderStatusRef.current.requestSubmit(); // Programmatically submit the form
            }
          }}
          onAdd={() => {
            setOpenAddOrderStatusesModal(true);
          }}
        />
        <GenericModal
          open={openAddOrderStatusesModal}
          title={""}
          content={
            <AddOrderStatus ref={formRef} onSubmit={addNewOrderStatus} />
          }
          onClose={() => {
            setOpenAddOrderStatusesModal(false);
          }}
          onCancel={() => {
            setOpenAddOrderStatusesModal(false);
          }}
          okButtonName={Vocabulary.save}
          handleSubmit={() => {
            if (formRef.current) {
              formRef.current.requestSubmit(); // Programmatically submit the form
            }
          }}
        />
      </div>
    </div>
  );
}
