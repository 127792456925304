import * as Yup from "yup";

export const OrderModel = Yup.object().shape({
    _id: Yup.string(),
    user: Yup.string(),
    phoneNumber: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    address: Yup.string(),
    client: Yup.string(),
    courier: Yup.string().nullable().default(null),
    status: Yup.string(),
    productLines: Yup.array(),
    discount: Yup.string().nullable().default(null),
  });