import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import style from "./Styles/genericDialog.module.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Vocabulary } from "../../Utils/Vocabulary";

// Styled component to make the dialog background transparent gray
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // transparent gray
  },
}));

type genericDialogPropsType = {
  open: boolean;
  title: string;
  content: React.ReactNode;
  onClose: () => void;
  onOk?: () => void;
  onCancel: () => void;
  okButtonName?: string;
  handleSubmit?: (payload: any) => any;
  onAdd?: () => void;
};

export default function GenericModal(
  genericDialogProps: genericDialogPropsType
) {
  const {
    open,
    title,
    content,
    onClose,
    onOk,
    onCancel,
    okButtonName,
    handleSubmit,
    onAdd,
  } = genericDialogProps;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={false} // Ensure dialog doesn't take full width
      maxWidth={false} // Prevent dialog from expanding unnecessarily
      sx={{ padding: 0 }} // Remove padding around the dialog
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className={style.dialogScroll}>
        {/* if the need arises we can send the name of the button as a*/}
        {onAdd && (
          <Button
            onClick={() => {
              onAdd();
            }}
            startIcon={<AddCircleIcon />}
            variant="contained"
            style={{
              color: "white",
              padding: "10px 15px 10px 15px",
              fontWeight: 600,
              borderRadius: "10px 10px 0px 0px",
              right: 0,
              zIndex: 1000,
            }}
          >
            {Vocabulary.addNewOrderStatus}
          </Button>
        )}
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        {(handleSubmit || onOk) && (
          <Button
            onClick={handleSubmit ? handleSubmit : onOk}
            color="primary"
            autoFocus
          >
            {okButtonName ? okButtonName : "OK"}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
}
