import * as Yup from "yup";
import { Vocabulary } from "../Utils/Vocabulary";

export const OrderStatusModel = Yup.object().shape({
  _id: Yup.string(),
  name: Yup.string().required(Vocabulary.required),
  displayName: Yup.string().required(Vocabulary.required),
  color: Yup.string().required(Vocabulary.required),
});

export const OrderStatusModelArray = Yup.object().shape({
  status: Yup.array()
    .of(OrderStatusModel)
    .required(),
});
