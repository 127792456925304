import React, { createContext, useContext, useState } from "react";
import { Product } from "../Types/Product";
import { Category } from "../Types/Category";
import { Client } from "../Types/Client";
import { User } from "../Types/User";
import { Order } from "../Types/Order";
import { ProductLine } from "../Types/ProductLine";
import { useEntityActions } from "../hooks/UseEntityActions";
import { Settings } from "../Types/Settings";
import { Statuses } from "../Types/Statuses";
import { Roles } from "../Types/Roles";
interface GlobalContextType {
  settings: Settings[];
  statuses: Statuses[];
  products: Product[];
  categories: Category[];
  productLines: ProductLine[];
  clients: Client[];
  users: User[];
  orders: Order[];
  roles: Roles[];
  formData: any;
  nrOrders: number;
  nrProduse: number;
  nrCategorii: number;
  nrUsers: number;
  setProductLines: React.Dispatch<React.SetStateAction<ProductLine[]>>;
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
  setClients: React.Dispatch<React.SetStateAction<Client[]>>;
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setRoles: React.Dispatch<React.SetStateAction<Roles[]>>;
  setOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  setSettings: React.Dispatch<React.SetStateAction<Settings[]>>;
  setStatuses: React.Dispatch<React.SetStateAction<Statuses[]>>;
  getProducts: (page?: number, perPage?: number) => Promise<void>;
  getCategories: (page?: number, perPage?: number) => Promise<void>;
  getClients: () => Promise<void>;
  getUsers: (page?: number, perPage?: number) => Promise<void>;
  getRoles: () => Promise<void>;
  getOrders: (
    page?: number,
    perPage?: number,
    status?: string
  ) => Promise<void>;
  getProductLines: () => Promise<void>;
  getSettings: () => Promise<void>;
  getStatuses: () => Promise<void>;
  getUserBySearchCriteria: (
    page: number,
    perPage: number,
    criteria: string
  ) => Promise<void>;
  filterCategories: (
    page: number,
    perPage: number,
    name: string
  ) => Promise<void>;
  filterProducts: (
    page: number,
    perPage: number,
    name: string
  ) => Promise<void>;
  filterUsers: (page: number, perPage: number, name: string) => Promise<void>;
  filterOrders: (page: number, perPage: number, name: string) => Promise<void>;
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string>(
    localStorage.getItem("access_token") || ""
  );

  const [formData, setFormData] = useState<any>({});
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Roles[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [nrOrders, setNrOrders] = useState<number>(0);
  const [nrProduse, setNrProduse] = useState<number>(0);
  const [nrCategorii, setNrCategorii] = useState<number>(0);
  const [nrUsers, setNrUsers] = useState<number>(0);
  const [productLines, setProductLines] = useState<ProductLine[]>([]);
  const [settings, setSettings] = useState<Settings[]>([]);
  const [statuses, setStatuses] = useState<Statuses[]>([]);

  const { getAll: getAllProducts } = useEntityActions("products", token);
  const { getAll: getAllCategories } = useEntityActions("categories", token);
  const { getAll: getAllClients } = useEntityActions("clients");
  const { getAll: getAllUsers } = useEntityActions("user", token);
  const { getAll: getAllRoles } = useEntityActions("roles", token);
  const { getAll: getAllOrders } = useEntityActions("orders", token);
  const { getAll: getAllProductLines } = useEntityActions("productLines");
  const { getAll: getAllSettings } = useEntityActions("settings");
  const { getAll: getAllStatuses } = useEntityActions("orderStatus", token);
  const { getAll: getUserByCriteria } = useEntityActions("user/filters", token);
  const { getAll: getFilterCategories } = useEntityActions(
    "categories/filter",
    token
  );
  const { getAll: getFilterProducts } = useEntityActions(
    "products/filter",
    token
  );
  const { getAll: getFilterUsers } = useEntityActions("user/filter", token);
  const { getAll: getFilterOrders } = useEntityActions("orders/filter", token);
  

  const filterCategories = async (
    page: number,
    perPage: number,
    name: string
  ) => {
    try {
      const response: any = await getFilterCategories(page - 1, perPage, name);
      setCategories(response.data.categories || []);
      setNrCategorii(response.data.total || 0);
    } catch (error) {
      console.error("Error getting categories:", error);
    }
  };

  const filterOrders = async (
    page: number,
    perPage: number,
    name: string
  ) => {
    try {
      const response: any = await getFilterOrders(page - 1, perPage, name);
      setOrders(response.data || []);
      setNrOrders(response.count || 0);
    } catch (error) {
      console.error("Error getting categories:", error);
    }
  };

  const filterProducts = async (
    page: number,
    perPage: number,
    name: string
  ) => {
    try {
      const response: any = await getFilterProducts(page - 1, perPage, name);
      setProducts(response.data.products || []);
      setNrProduse(response.data.total || 0);
    } catch (error) {
      console.error("Error getting categories:", error);
    }
  };

  const filterUsers = async (page: number, perPage: number, name: string) => {
    try {
      const response: any = await getFilterUsers(page - 1, perPage, name);
      setUsers(response.data || []);
      setNrUsers(response.count || 0);
    } catch (error) {
      console.error("Error getting categories:", error);
    }
  };

  const getProducts = async (page?: number, perPage?: number) => {
    try {
      const response: any = await getAllProducts(page, perPage);
      setProducts(response.data.products || []);
      setNrProduse(response.data.total || 0);
    } catch (error) {
      console.error("Error getting products:", error);
    }
  };

  const getCategories = async (page?: number, perPage?: number) => {
    try {
      const response: any = await getAllCategories(page, perPage);
      setCategories(response.data.categories || []);
      setNrCategorii(response.data.total || 0);
    } catch (error) {
      console.error("Error getting categories:", error);
    }
  };

  const getClients = async () => {
    try {
      const response: any = await getAllClients();
      setClients(response.data || []);
    } catch (error) {
      console.error("Error getting clients:", error);
    }
  };

  const getUsers = async (page?: number, perPage?: number) => {
    try {
      const response: any = await getAllUsers(page, perPage);
      setUsers(response.data.users || []);
      setNrUsers(response.data.total || 0);
    } catch (error) {
      console.error("Error getting users:", error);
    }
  };

  const getRoles = async () => {
    try {
      const response: any = await getAllRoles();
      setRoles(response.result || []);
    } catch (error) {
      console.error("Error getting roles:", error);
    }
  };

  const getOrders = async (
    page?: number,
    perPage?: number,
    status?: string
  ) => {
    try {
      const response: any = await getAllOrders(page, perPage, status);
      setOrders(response.data.orders || []);
      setNrOrders(response.data.total || 0);
    } catch (error) {
      console.error("Error getting orders:", error);
    }
  };

  const getProductLines = async () => {
    try {
      const response: any = await getAllProductLines();
      setProductLines(response.data || []);
    } catch (error) {
      console.error("Error getting product lines:", error);
    }
  };
  const getSettings = async () => {
    try {
      const response: any = await getAllSettings();
      setSettings(response.data || []);
    } catch (error) {
      console.error("Error getting settings:", error);
    }
  };

  const getStatuses = async () => {
    try {
      const response: any = await getAllStatuses();
      setStatuses(response.result || []);
    } catch (error) {
      console.error("Error getting statuses:", error);
    }
  };

  const getUserBySearchCriteria = async (
    page: number,
    perPage: number,
    criteria: string
  ) => {
    try {
      const response: any = await getUserByCriteria(page, perPage, criteria);
      return response;
    } catch (error) {
      console.error("Error getting statuses:", error);
    }
  };

  const value = {
    products,
    categories,
    clients,
    users,
    roles,
    orders,
    productLines,
    formData,
    statuses,
    nrOrders,
    nrProduse,
    nrCategorii,
    nrUsers,
    setProducts,
    setCategories,
    setClients,
    setUsers,
    setOrders,
    setNrOrders,
    setProductLines,
    setFormData,
    setStatuses,
    setRoles,
    getProducts,
    getCategories,
    getClients,
    getUsers,
    getOrders,
    getProductLines,
    settings,
    setSettings,
    getSettings,
    getStatuses,
    getRoles,
    getUserBySearchCriteria,
    filterCategories,
    filterProducts,
    filterUsers,
    filterOrders,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
