import { Button, Divider, IconButton, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrderStatusModelArray } from "../../Models/OrderStatusModel";
import { HexColorPicker } from "react-colorful";
import DeleteIcon from "@mui/icons-material/Delete";
import { forwardRef } from "react";

type orderStatusesProps = {
  statuses: any;
  onSubmit: any;
  deleteStatus: any;
  showColorPicker: any;
  setShowColorPicker: any;
};
const OrderStatuses = forwardRef<HTMLFormElement, orderStatusesProps>(
  (
    { statuses, onSubmit, deleteStatus, showColorPicker, setShowColorPicker },
    ref
  ) => {
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues: { status: statuses },
      resolver: yupResolver(OrderStatusModelArray),
    });

    // Watch for changes in the statuses
    const watchedStatuses = watch("status");

    return (
      <form
        ref={ref}
        onSubmit={handleSubmit(onSubmit)}
        id="updateOrderStatus"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div>
          {watchedStatuses.map((status: any, key: number) => (
            <div key={key}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  {/* Hidden Field for ID */}
                  <Controller
                    name={`status.${key}._id`}
                    control={control}
                    defaultValue={status._id}
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                  <Controller
                    name={`status.${key}.name`}
                    control={control}
                    defaultValue={status.name}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        disabled
                        style={{ marginRight: "10px", marginBottom: "10px" }}
                        fullWidth
                        label="Name"
                        variant="outlined"
                        size="small"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`status.${key}.displayName`}
                    control={control}
                    defaultValue={status.displayName}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        style={{ marginRight: "10px" }}
                        fullWidth
                        label="Display Name"
                        variant="outlined"
                        size="small"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </div>
                <Button
                  style={{
                    backgroundColor: status.color,
                    marginRight: "3px",
                  }}
                  onClick={() => setShowColorPicker(key)}
                ></Button>
                {showColorPicker.includes(key) && (
                  <Controller
                    name={`status.${key}.color`}
                    control={control}
                    defaultValue={status.color}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <HexColorPicker
                        color={value} // Controlled value
                        style={{ height: "100px" }}
                        onChange={(newColor) => {
                          onChange(newColor); // Update the form state
                          setValue(`status.${key}.color`, newColor); // Update specific color
                        }}
                      />
                    )}
                  />
                )}

                <IconButton onClick={() => deleteStatus(status._id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </form>
    );
  }
);

export default OrderStatuses;
