export default function MobilePrivacyPolicy() {
  return (
    <div
      style={{ position: "relative", maxHeight: "100vh", overflowY: "auto" }}
    >
      <div style={{ padding: "16px 0" }}>
        <div
          style={{
            width: "90%",
            maxWidth: "1024px",
            margin: "0 auto",
          }}
        >
          <p
            style={{
              fontSize: "40px",
              lineHeight: "60px",
              fontWeight: "700",
              margin: "0",
            }}
          >
            Politica de Confidențialitate pentru CasaVatra
          </p>
        </div>
      </div>

      <div>
        <div
          style={{
            position: "relative",
            width: "90%",
            maxWidth: "1024px",
            margin: "0 auto",
          }}
        >
          <div id="ro">
            <h1>Politica de Confidențialitate</h1>
            <p>Ultima actualizare: 9 ianuarie 2025</p>
            <p>
              Această Politică de Confidențialitate descrie politicile și
              procedurile noastre privind colectarea, utilizarea și divulgarea
              informațiilor dvs. atunci când utilizați Serviciul și vă
              informează despre drepturile dvs. de confidențialitate și despre
              modul în care legea vă protejează.
            </p>
            <p>
              Folosim datele dvs. personale pentru a furniza și a îmbunătăți
              Serviciul. Prin utilizarea Serviciului, sunteți de acord cu
              colectarea și utilizarea informațiilor în conformitate cu această
              Politică de Confidențialitate.
            </p>
            <h2>Interpretare și definiții</h2>
            <h3>Interpretare</h3>
            <p>
              Cuvintele al căror primă literă este scrisă cu majusculă au
              semnificații definite în condițiile următoare. Definițiile
              următoare vor avea aceeași semnificație indiferent dacă apar la
              singular sau la plural.
            </p>
            <h3>Definiții</h3>
            <p>În scopul acestei Politici de Confidențialitate:</p>
            <ul>
              <li>
                <p>
                  <strong>Cont</strong> înseamnă un cont unic creat pentru
                  accesarea Serviciului nostru sau a unor părți ale acestuia.
                </p>
              </li>
              <li>
                <p>
                  <strong>Afiliat</strong> înseamnă o entitate care controlează,
                  este controlată de sau se află sub control comun cu o parte,
                  unde „control” înseamnă deținerea a 50% sau mai mult din
                  acțiuni sau alte interese de vot pentru alegerea directorilor
                  sau autorității de conducere.
                </p>
              </li>
              <li>
                <p>
                  <strong>Aplicație</strong> se referă la CasaVatra, programul
                  software furnizat de Companie.
                </p>
              </li>
              <li>
                <p>
                  <strong>Compania</strong> (denumită fie „Compania”, „Noi” sau
                  „Al nostru” în acest Acord) se referă la CasaVatra, Str. Gării
                  nr. 9, Rădăuți, jud. Suceava.
                </p>
              </li>
              <li>
                <p>
                  <strong>Țara</strong> se referă la: România
                </p>
              </li>
              <li>
                <p>
                  <strong>Dispozitiv</strong> înseamnă orice dispozitiv care
                  poate accesa Serviciul, cum ar fi un computer, un telefon
                  mobil sau o tabletă digitală.
                </p>
              </li>
              <li>
                <p>
                  <strong>Date personale</strong> reprezintă orice informație
                  care se referă la o persoană identificată sau identificabilă.
                </p>
              </li>
              <li>
                <p>
                  <strong>Serviciu</strong> se referă la Aplicație.
                </p>
              </li>
              <li>
                <p>
                  <strong>Furnizor de servicii</strong> înseamnă orice persoană
                  fizică sau juridică care procesează datele în numele
                  Companiei. Aceasta se referă la companii terțe sau persoane
                  angajate de Companie pentru a facilita Serviciul, pentru a
                  furniza Serviciul în numele Companiei sau pentru a analiza
                  modul în care este utilizat Serviciul.
                </p>
              </li>
              <li>
                <p>
                  <strong>Date de utilizare</strong> se referă la datele
                  colectate automat, fie generate prin utilizarea Serviciului
                  sau din infrastructura Serviciului însuși (de exemplu, durata
                  unei vizite pe o pagină).
                </p>
              </li>
              <li>
                <p>
                  <strong>Dvs.</strong> înseamnă persoana care accesează sau
                  utilizează Serviciul sau compania, sau entitatea juridică în
                  numele căreia această persoană accesează sau utilizează
                  Serviciul, după caz.
                </p>
              </li>
            </ul>
            <h2>Colectarea și utilizarea datelor dvs. personale</h2>
            <h3>Tipuri de date colectate</h3>
            <h4>Date personale</h4>
            <p>
              În timp ce utilizați Serviciul nostru, vă putem solicita să ne
              furnizați anumite informații personale identificabile care pot fi
              utilizate pentru a vă contacta sau identifica. Informațiile
              personale identificabile pot include, dar nu se limitează la:
            </p>
            <ul>
              <li>
                <p>Adresă de email</p>
              </li>
              <li>
                <p>Nume și prenume</p>
              </li>
              <li>
                <p>Număr de telefon</p>
              </li>
              <li>
                <p>Adresă, Județ, Cod poștal, Oraș</p>
              </li>
              <li>
                <p>Date de utilizare</p>
              </li>
            </ul>

            <h4>Date de utilizare</h4>
            <p>
              Datele de utilizare sunt colectate automat atunci când utilizați
              Serviciul.
            </p>
            <p>
              Datele de utilizare pot include informații precum adresa IP a
              Dispozitivului dumneavoastră (de exemplu, adresa IP), tipul
              browser-ului, versiunea browser-ului, paginile Serviciului nostru
              pe care le vizitați, data și ora vizitei, timpul petrecut pe acele
              pagini, identificatori unici ai dispozitivului și alte date de
              diagnosticare.
            </p>
            <p>
              Când accesați Serviciul prin intermediul unui dispozitiv mobil,
              este posibil să colectăm anumite informații automat, inclusiv, dar
              fără a ne limita la tipul dispozitivului mobil utilizat, ID-ul
              unic al dispozitivului mobil, adresa IP a dispozitivului mobil,
              sistemul de operare mobil, tipul browser-ului de Internet mobil
              utilizat, identificatori unici ai dispozitivului și alte date de
              diagnosticare.
            </p>
            <p>
              De asemenea, putem colecta informații trimise de browser-ul
              dumneavoastră ori de câte ori vizitați Serviciul nostru sau când
              accesați Serviciul printr-un dispozitiv mobil.
            </p>
            <h3>Utilizarea Datelor Dumneavoastră Personale</h3>
            <p>
              Compania poate utiliza Datele personale pentru următoarele
              scopuri:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Pentru a furniza și menține Serviciul nostru</strong>,
                  inclusiv pentru a monitoriza utilizarea acestuia.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pentru a gestiona Contul Dumneavoastră:</strong>{" "}
                  pentru a administra înregistrarea dumneavoastră ca utilizator
                  al Serviciului. Datele personale furnizate pot oferi acces la
                  diferite funcționalități ale Serviciului disponibile pentru
                  utilizatorii înregistrați.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pentru îndeplinirea unui contract:</strong>{" "}
                  dezvoltarea, conformarea și executarea contractului de
                  achiziție pentru produsele, articolele sau serviciile
                  achiziționate sau orice alt contract încheiat cu noi prin
                  intermediul Serviciului.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pentru a vă contacta:</strong> pentru a vă contacta
                  prin email, apeluri telefonice, SMS-uri sau alte forme
                  echivalente de comunicare electronică, cum ar fi notificările
                  push ale aplicației mobile, referitor la actualizări sau
                  comunicări informative despre funcționalități, produse sau
                  servicii contractate, inclusiv actualizări de securitate,
                  atunci când este necesar sau rezonabil pentru implementarea
                  acestora.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pentru a vă oferi</strong> știri, oferte speciale și
                  informații generale despre alte bunuri, servicii și evenimente
                  pe care le oferim și care sunt similare celor pe care le-ați
                  achiziționat deja sau despre care ați întrebat, cu excepția
                  cazului în care ați optat să nu primiți astfel de informații.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pentru a vă gestiona cererile:</strong> pentru a
                  răspunde și a gestiona cererile dumneavoastră adresate către
                  noi.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pentru transferuri de afaceri:</strong> putem utiliza
                  informațiile dumneavoastră pentru a evalua sau a efectua o
                  fuziune, cesiune, restructurare, reorganizare, dizolvare sau
                  altă vânzare sau transfer al unora sau tuturor activelor
                  noastre, fie ca o afacere funcțională, fie ca parte a unei
                  lichidări, faliment sau procedură similară, în care Datele
                  personale deținute de noi despre utilizatorii Serviciului
                  nostru sunt printre activele transferate.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pentru alte scopuri:</strong> putem utiliza
                  informațiile dumneavoastră pentru alte scopuri, cum ar fi
                  analiza datelor, identificarea tendințelor de utilizare,
                  determinarea eficacității campaniilor noastre promoționale și
                  evaluarea și îmbunătățirea Serviciului nostru, a produselor,
                  serviciilor, marketingului și experienței dumneavoastră.
                </p>
              </li>
            </ul>
            <p>
              Putem partaja informațiile dumneavoastră personale în următoarele
              situații:
            </p>
            <ul>
              <li>
                <strong>Cu furnizorii de servicii:</strong> putem partaja
                informațiile dumneavoastră personale cu furnizorii de servicii
                pentru a monitoriza și analiza utilizarea Serviciului nostru,
                pentru a vă contacta.
              </li>
              <li>
                <strong>Pentru transferuri de afaceri:</strong> putem partaja
                sau transfera informațiile dumneavoastră personale în legătură
                cu, sau în timpul negocierilor pentru orice fuziune, vânzare de
                active ale Companiei, finanțare sau achiziție a întregii sau
                unei părți din afacerea noastră de către o altă companie.
              </li>
              <li>
                <strong>Cu afiliații:</strong> putem partaja informațiile
                dumneavoastră cu afiliații noștri, caz în care le vom solicita
                să respecte această Politică de confidențialitate. Afiliații
                includ compania noastră-mamă și orice alte filiale, parteneri de
                joint venture sau alte companii pe care le controlăm sau care
                sunt sub control comun cu noi.
              </li>
              <li>
                <strong>Cu partenerii de afaceri:</strong> putem partaja
                informațiile dumneavoastră cu partenerii noștri de afaceri
                pentru a vă oferi anumite produse, servicii sau promoții.
              </li>
              <li>
                <strong>Cu alți utilizatori:</strong> când partajați informații
                personale sau interacționați în zonele publice cu alți
                utilizatori, aceste informații pot fi vizualizate de toți
                utilizatorii și pot fi distribuite public în afara Serviciului.
              </li>
              <li>
                <strong>Cu consimțământul dumneavoastră:</strong> putem dezvălui
                informațiile dumneavoastră personale pentru orice alt scop cu
                consimțământul dumneavoastră.
              </li>
            </ul>

            <h3>Retenția Datelor Dumneavoastră Personale</h3>
            <p>
              Compania va păstra Datele Dumneavoastră Personale doar atât timp
              cât este necesar pentru scopurile stabilite în această Politică de
              Confidențialitate. Vom păstra și utiliza Datele Dumneavoastră
              Personale în măsura necesară pentru a respecta obligațiile noastre
              legale (de exemplu, dacă ni se cere să păstrăm datele
              dumneavoastră pentru a respecta legile aplicabile), pentru a
              rezolva disputele și pentru a ne aplica acordurile și politicile
              legale.
            </p>
            <p>
              Compania va păstra, de asemenea, Datele de utilizare în scopuri de
              analiză internă. Datele de utilizare sunt de obicei păstrate
              pentru o perioadă mai scurtă de timp, cu excepția cazului în care
              aceste date sunt utilizate pentru a consolida securitatea sau
              pentru a îmbunătăți funcționalitatea Serviciului nostru, sau dacă
              suntem obligați legal să păstrăm aceste date pentru perioade mai
              lungi de timp.
            </p>
            <h3>Transferul Datelor Dumneavoastră Personale</h3>
            <p>
              Informațiile dumneavoastră, inclusiv Datele Personale, sunt
              procesate la birourile de operare ale Companiei și în orice alte
              locații unde se află părțile implicate în procesare. Aceasta
              înseamnă că aceste informații pot fi transferate către — și
              păstrate pe — computere situate în afara statului, provinciei,
              țării sau altor jurisdicții guvernamentale unde legile privind
              protecția datelor pot diferi de cele din jurisdicția
              dumneavoastră.
            </p>
            <p>
              Consimțământul dumneavoastră pentru această Politică de
              Confidențialitate, urmat de trimiterea unor astfel de informații,
              reprezintă acordul dumneavoastră pentru acest transfer.
            </p>
            <p>
              Compania va lua toate măsurile rezonabile pentru a se asigura că
              datele dumneavoastră sunt tratate în siguranță și în conformitate
              cu această Politică de Confidențialitate și că niciun transfer al
              Datelor Dumneavoastră Personale nu va avea loc către o organizație
              sau o țară decât dacă există controale adecvate în vigoare,
              inclusiv securitatea datelor dumneavoastră și a altor informații
              personale.
            </p>
            <h3>Ștergerea Datelor Dumneavoastră Personale</h3>
            <p>
              Aveți dreptul să ștergeți sau să solicitați să vă asistăm în
              ștergerea Datelor Personale pe care le-am colectat despre
              dumneavoastră.
            </p>
            <p>
              Serviciul nostru vă poate oferi posibilitatea de a șterge anumite
              informații despre dumneavoastră din cadrul Serviciului.
            </p>
            <p>
              Puteți actualiza, modifica sau șterge informațiile dumneavoastră
              în orice moment prin conectarea la Contul dumneavoastră, dacă
              aveți unul, și accesând secțiunea de setări a contului care vă
              permite să vă gestionați informațiile personale. De asemenea, ne
              puteți contacta pentru a solicita accesul la, corectarea sau
              ștergerea oricărei informații personale pe care ne-ați furnizat-o.
            </p>
            <p>
              Vă rugăm să rețineți, totuși, că este posibil să fim nevoiți să
              păstrăm anumite informații atunci când avem o obligație legală sau
              o bază legală pentru a face acest lucru.
            </p>
            <h3>Dezvăluirea Datelor Dumneavoastră Personale</h3>
            <h4>Tranzacții Comerciale</h4>
            <p>
              În cazul în care Compania este implicată într-o fuziune, achiziție
              sau vânzare de active, Datele Dumneavoastră Personale pot fi
              transferate. Vom furniza un anunț înainte ca Datele Dumneavoastră
              Personale să fie transferate și să devină supuse unei alte
              Politici de Confidențialitate.
            </p>
            <h4>Aplicarea Legii</h4>
            <p>
              În anumite circumstanțe, Compania poate fi obligată să dezvăluie
              Datele Dumneavoastră Personale dacă este cerută de lege sau ca
              răspuns la solicitări valide din partea autorităților publice (de
              exemplu, o instanță sau o agenție guvernamentală).
            </p>
            <h4>Alte cerințe legale</h4>
            <p>
              Compania poate dezvălui Datele Dumneavoastră Personale în
              convingerea de bună credință că o astfel de acțiune este necesară
              pentru:
            </p>
            <ul>
              <li>Respectarea unei obligații legale</li>
              <li>
                Protejarea și apărarea drepturilor sau proprietății Companiei
              </li>
              <li>
                Prevenirea sau investigarea unor posibile abateri în legătură cu
                Serviciul
              </li>
              <li>
                Protejarea siguranței personale a Utilizatorilor Serviciului sau
                a publicului
              </li>
              <li>Protejarea împotriva răspunderii legale</li>
            </ul>
            <h3>Securitatea Datelor Dumneavoastră Personale</h3>
            <p>
              Securitatea Datelor Dumneavoastră Personale este importantă pentru
              noi, dar amintiți-vă că nicio metodă de transmitere pe Internet
              sau metodă de stocare electronică nu este 100% sigură. Deși ne
              străduim să utilizăm mijloace comerciale acceptabile pentru a
              proteja Datele Dumneavoastră Personale, nu putem garanta
              securitatea absolută a acestora.
            </p>
            <h2>Confidențialitatea Copiilor</h2>
            <p>
              Serviciul nostru nu se adresează nimănui sub vârsta de 13 ani. Nu
              colectăm cu bună știință informații de identificare personală de
              la oricine sub vârsta de 13 ani. Dacă sunteți un părinte sau
              tutore și sunteți conștient că copilul dumneavoastră ne-a furnizat
              Date Personale, vă rugăm să ne contactați. Dacă devenim conștienți
              că am colectat Date Personale de la oricine sub vârsta de 13 ani
              fără consimțământul verificabil al părintelui, luăm măsuri pentru
              a elimina acele informații de pe serverele noastre.
            </p>
            <p>
              Dacă trebuie să ne bazăm pe consimțământ ca bază legală pentru
              procesarea informațiilor dumneavoastră și țara dumneavoastră
              necesită consimțământul unui părinte, este posibil să solicităm
              consimțământul părintelui înainte de a colecta și utiliza acele
              informații.
            </p>
            <h2>Link-uri către Alte Site-uri Web</h2>
            <p>
              Serviciul nostru poate conține link-uri către alte site-uri web
              care nu sunt operate de noi. Dacă faceți clic pe un link al unei
              terțe părți, veți fi direcționat către site-ul acelei terțe părți.
              Vă recomandăm insistent să revizuiți Politica de Confidențialitate
              a fiecărui site pe care îl vizitați.
            </p>
            <p>
              Nu avem control asupra și nu ne asumăm responsabilitatea pentru
              conținutul, politicile de confidențialitate sau practicile
              oricăror site-uri sau servicii ale terților.
            </p>
            <h2>Modificări ale acestei Politici de Confidențialitate</h2>
            <p>
              Putem actualiza Politica noastră de Confidențialitate din când în
              când. Vă vom notifica cu privire la orice modificări prin postarea
              noii Politici de Confidențialitate pe această pagină.
            </p>
            <p>
              Vă vom anunța prin email și/sau un anunț proeminent pe Serviciul
              nostru, înainte ca modificarea să devină efectivă și vom actualiza
              data "Ultima actualizare" din partea de sus a acestei Politici de
              Confidențialitate.
            </p>
            <p>
              Sunteți sfătuit să revizuiți periodic această Politică de
              Confidențialitate pentru orice modificări. Modificările acestei
              Politici de Confidențialitate devin efective atunci când sunt
              postate pe această pagină.
            </p>
            <h2>Contactați-ne</h2>
            <p>
              Dacă aveți întrebări despre această Politică de Confidențialitate,
              ne puteți contacta:
            </p>
            <ul>
              <li>Prin email: exemplu@email.com</li>
              <li>
                <p>
                  Prin vizitarea acestei pagini de pe site-ul nostru:{" "}
                  <a
                    href="https://comenzi.casavatra.ro/mobile-privacy-policy"
                    rel="external nofollow noopener"
                    target="_blank"
                  >
                    https://comenzi.casavatra.ro/mobile-privacy-policy
                  </a>
                </p>
              </li>
              <li style={{ marginBottom: 50 }}>Prin telefon: 0748 239 123</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
