import Config from "./Config";

export const urlEnum = {
  login: `${Config.apiUrl}/auth/login`,
  logout: `${Config.apiUrl}/auth/signout`,
  register: `${Config.apiUrl}/auth/register`,
  users: `${Config.apiUrl}/user`,
  workflows: `${Config.apiUrl}/workflows`,
  steps: `${Config.apiUrl}/workflowStep`,
  statuses: `${Config.apiUrl}/statuses`,
  orders: `${Config.apiUrl}/orders`,
  products: `${Config.apiUrl}/products`,
  devices: `${Config.apiUrl}/devices`,
  customers: `${Config.apiUrl}/customers`,
  deliveries: `${Config.apiUrl}/deliveries`,
  lines: `${Config.apiUrl}/lines`,
  locations: `${Config.apiUrl}/locations`,
  notes: `${Config.apiUrl}/notes`,
  shipments: `${Config.apiUrl}/shipments`,
  roles: `${Config.apiUrl}/roles`,
  reports: `${Config.apiUrl}/reports`,
  ordersReports: `${Config.apiUrl}/ordersReports`,
  workflowsReports: `${Config.apiUrl}/workflowsReports`,
  category: `${Config.apiUrl}/categories`,
  forgotPassword: `${Config.apiUrl}/auth/forgot-password`,
  resetPassword: `${Config.apiUrl}/auth/reset-password`,
  orderStatuses: `${Config.apiUrl}/orderStatus`,
};

export const localUrlEnum = {
  login: "/login",
  products: "/products",
  product: "/product",
  dashboard: "/",
  categories: "/categories",
  category: "/category",
  clients: "/clients",
  client: "/client",
  orders: "/orders",
  order: "/order",
  productLines: "/productLines",
  productLine: "/productLine",
  users: "/users",
  user: "/user",
  roles: "/roles",
  role: "/role",
  settings: "/settings",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
};

export const basicOperationsEnum = {
  create: "/create",
  update: "/update",
  delete: "/delete",
  import: "/import",
  export: "/export",
};
