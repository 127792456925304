import React, { useEffect, useState } from "react";
import styles from "./forgotPasswordPage.module.css";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ResetPasswordModel } from "../../Models/ResetPasswordModel";
import { jwtDecode } from "jwt-decode";

export default function ForgotPassword() {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(ResetPasswordModel),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    console.log("Clearing localStorage...");
    localStorage.clear();
  },[]);

  // Function to toggle password visibility
  const handleClickShowPassword = () =>
    setShowPassword((prev: boolean) => !prev);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((prev: boolean) => !prev);

  // Function to prevent mouse down default behavior
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => event.preventDefault();

  /**
   *
   */
  async function handleUserDataSubmit(payload: any) {
    // eslint-disable-next-line no-console
    const params = new URLSearchParams(location.search);
    const token: any = params.get("token");
    localStorage.setItem("access_token", token);
    if (token) {
      try {
        // Decode the token
        const decoded: any = jwtDecode(token);
        payload.email = decoded.email;
        const response = await postData(urlEnum.resetPassword, payload, token);
        if (response) {
          navigate(localUrlEnum.login);
        }
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }

  const onError = (model: any) => {
    //
  };

  return (
    <div className={styles.registerContainer}>
      <div className={styles.imageContainer}>
        <img src="/casaVatraSiglaAlb.svg" alt="CASAVATRA Img" />
      </div>
      <div className={styles.formContainer}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid
            component="div"
            sx={{
              flex: 2,
              mt: 8,
              mb: 0,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h1>{Vocabulary.changePassword}</h1>
          </Grid>
          <Grid component="div" sx={{ flex: 4, mt: 8, mb: 2, width: "100%" }}>
            <form
              onSubmit={handleSubmit(handleUserDataSubmit, onError)}
              method="post"
              id="checkUserData"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Controller
                name="password"
                defaultValue=""
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    fullWidth
                    style={{
                      marginBottom: 15,
                    }}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                    id="password"
                    label={Vocabulary.password}
                    size="small"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                defaultValue=""
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={onChange}
                    fullWidth
                    style={{
                      marginBottom: 15,
                    }}
                    value={value}
                    error={!!error}
                    helperText={error?.message}
                    id="confirmPassword"
                    label={Vocabulary.confirmPassword}
                    size="small"
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button variant="contained" type="submit">
                {Vocabulary.resetPassword}
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
