import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_WEBSOCKET_URL || "wss://comenzi.casavatra.ro", {
    transports: ["websocket"],
    extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    secure: true
});

export default socket;