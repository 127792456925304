// import DashboardIcon from '@mui/icons-material/Dashboard';
import { localUrlEnum } from "../Utils/UrlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ClassIcon from "@mui/icons-material/Class";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import GroupIcon from "@mui/icons-material/Group";

export const ROUTER_DASHBOARD = {
  routes: [
    "/",
    "/login",
    localUrlEnum.orders,
    localUrlEnum.products,
    localUrlEnum.categories,
    localUrlEnum.users,
    "*",
  ],
};

export const MAIN_SLIDER_DASHBOARD = [
  {
    name: Vocabulary.orders,
    path: localUrlEnum.orders,
    icon: <FastfoodIcon />,
    orderId: 0,
  },
  {
    name: Vocabulary.products,
    path: localUrlEnum.products,
    icon: <MenuBookIcon />,
    orderId: 1,
  },
  {
    name: Vocabulary.categories,
    path: localUrlEnum.categories,
    icon: <ClassIcon />,
    orderId: 2,
  },
  {
    name: Vocabulary.users,
    path: localUrlEnum.users,
    icon: <GroupIcon />,
    orderId: 3,
  },
];
